import React, { useState, useEffect } from 'react';
// import headerBgMobile from '../assets/headerBgMobile.png';
// import headerBgDesktop from '../assets/headerBgDesktop.png';
import avocatoHeader from '../assets/avocatoHeader.png';
import navbarIcon from '../assets/navbarIcon.png';
import buySato from '../assets/buySato.png';
import chart from '../assets/chart.png';

import headerBgMobile from '../assets/videos/headerAnimationMobileV2.webm';
import headerBgDesktop from '../assets/videos/headerAnimationDesktopV4.webm';
import avocatoHeaderBig from '../assets/avocatoHeaderBig.png';

// icons
import meta from '../assets/icons/meta.png';
import eagle from '../assets/icons/eagle.png';
import s from '../assets/icons/s.png';
import tiktok from '../assets/icons/tiktok.png';
import telegram from '../assets/icons/telegram.png';
import ig from '../assets/icons/ig.png';
import x from '../assets/icons/x.png';
import pumpfun from '../assets/icons/pumpfun.png';

const Header = () => {
  const [backgroundVideo, setBackgroundVideo] = useState(headerBgMobile);
  const [isNavbarOpen, setIsNavbarOpen] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);

  const scrollToSection = (id) => {
    const section = document.getElementById(id);
    if (section) {
      const yOffset = 150;
      const yPosition = section.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: yPosition, behavior: 'smooth' });
      setIsNavbarOpen(false);
      setIsAnimating(false);
    }
  };

  const handleNavbarToggle = () => {
    if (isNavbarOpen) {
      setIsAnimating(true);
      setTimeout(() => {
        setIsNavbarOpen(false);
        setIsAnimating(false);
      }, 700);
    } else {
      setIsNavbarOpen(true);
    }
  };

  useEffect(() => {
    const mediaQuery = window.matchMedia('(min-width: 1024px)');

    const handleMediaQueryChange = (e) => {
      if (e.matches) {
        setBackgroundVideo(headerBgDesktop);
      } else {
        setBackgroundVideo(headerBgMobile);
      }
    };

    handleMediaQueryChange(mediaQuery);
    mediaQuery.addEventListener('change', handleMediaQueryChange);

    return () => {
      mediaQuery.removeEventListener('change', handleMediaQueryChange);
    };
  }, []);

  return (
    <div className="relative z-50">
      {/* Main Header Section with Background Video */}
      <div
        id="header"
        className="relative min-h-[175vh] md:min-h-[150vh] bg-cover bg-center h-screen flex flex-col justify-between items-center p-4 pb-20 z-50 -mb-48 sm:-mb-44 lg:-mb-52 2xl:-mb-60 3xl:-mb-96"
        style={{
          borderBottomLeftRadius: '50% 15%', borderBottomRightRadius: '50% 15%', overflow: 'hidden',
        }}
      >
        {/* Background Video */}
        <video
          src={backgroundVideo}
          autoPlay
          loop
          muted
          playsInline
          className="absolute inset-0 w-full h-full object-cover z-0"
          style={{ objectPosition: 'center top' }}
          type="video/webm"
        />

        {/* Header Section */}
        <div className="flex justify-between lg:justify-center gap-4 w-full items-center relative">
          {/* Header Image - larger on 3xl */}
          <img src={avocatoHeader} alt="Avocato" className="h-20 3xl:h-32" />

          <div
            className="rounded-full hidden lg:block"
            style={{
              background: 'linear-gradient(to right, #c9cacc, #e4e5e6)',
              border: '2px solid #a8aeae',
            }}
          >
            <ul className="flex justify-between px-10 py-6 gap-2 xl:gap-11 3xl:px-12 3xl:py-8 3xl:gap-14">
              <li
                onClick={() => scrollToSection('header')}
                className="text-custom-brown font-bold text-xl xl:text-2xl 3xl:text-5xl hover:text-green-400 cursor-pointer"
              >
                HOME
              </li>
              <li
                onClick={() => scrollToSection('about')}
                className="text-custom-brown font-bold text-xl xl:text-2xl 3xl:text-5xl hover:text-green-400 cursor-pointer"
              >
                ABOUT
              </li>
              <li
                onClick={() => scrollToSection('howToBuy')}
                className="text-custom-brown font-bold text-xl xl:text-2xl 3xl:text-5xl hover:text-green-400 cursor-pointer"
              >
                HOW TO BUY
              </li>
              <li
                onClick={() => scrollToSection('atonomics')}
                className="text-custom-brown font-bold text-xl xl:text-2xl 3xl:text-5xl hover:text-green-400 cursor-pointer"
              >
                TOKENOMICS
              </li>
            </ul>
          </div>

          {/* Navbar Icon - larger on 3xl */}
          <img
            src={navbarIcon}
            alt="Navbar Icon"
            className="h-20 3xl:h-24 cursor-pointer lg:hidden ml-auto"
            onClick={handleNavbarToggle}
          />

          {/* Buy Button Image - larger on 3xl */}
          <a href="https://dexscreener.com/solana/c4zub3dbceu7jqosu3ikc5dkkqbxdttuvebncjcesl7g" target="_blank" rel="noopener noreferrer">
            <img
              src={buySato}
              alt="Buy $ATO!"
              className="lg:h-16 xl:h-20 3xl:h-28 hidden lg:block duration-300 hover:scale-110"
            />
          </a>
        </div>

        {/* Navbar Section */}
        {(isNavbarOpen || isAnimating) && (
          <div
            className={`absolute left-4 right-4 mt-4 bg-gradient-to-b from-[#bebfc1] to-[#efefef] border-[1px] border-[#adadad] rounded-lg shadow-lg p-8 flex flex-col items-center space-y-4 transition-transform duration-300 ease-in-out ${isNavbarOpen && !isAnimating ? 'navbar-slide-in' : 'navbar-slide-out'}`}
            style={{
              top: '100px',
              zIndex: 100,
            }}
          >
            <span
              onClick={() => scrollToSection('header')}
              className="text-brown-800 text-xl font-bold custom-font hover:text-green-600 cursor-pointer"
            >
              HOME
            </span>
            <div className="w-full border-t-[1px] border-[#acc7b8]" />
            <span
              onClick={() => scrollToSection('about')}
              className="text-brown-800 text-xl font-bold custom-font hover:text-green-600 cursor-pointer"
            >
              ABOUT
            </span>
            <div className="w-full border-t-[1px] border-[#acc7b8]" />
            <span
              onClick={() => scrollToSection('howToBuy')}
              className="text-brown-800 text-xl font-bold custom-font hover:text-green-600 cursor-pointer"
            >
              HOW TO BUY
            </span>
            <div className="w-full border-t-[1px] border-[#acc7b8]" />
            <span
              onClick={() => scrollToSection('atonomics')}
              className="text-brown-800 text-xl font-bold custom-font hover:text-green-600 cursor-pointer"
            >
              TOKENOMICS
            </span>
          </div>
        )}

        <img src={avocatoHeaderBig} alt="AVOCATO" className="absolute z-40 mt-52 lg:mt-20 xl:mt-32 2xl:mt-48 3xl:mt-60 3xl:h-80" />

        {/* <img src={avocatoHeaderBig} alt="AVOCATO" className="z-40 -mt-[30rem] md:mt-0 lg:-mt-[30rem] xl:-mt-[30rem] 3xl:-mt-[50rem] 3xl:h-80" /> */}

        {/* Buttons Section */}
        <div className="flex flex-col lg:flex-row space-y-8 lg:space-y-0 lg:space-x-8 mb-20 md:mb-20 3xl:mb-24 z-50">
          <a href="https://dexscreener.com/solana/c4zub3dbceu7jqosu3ikc5dkkqbxdttuvebncjcesl7g" target="_blank" rel="noopener noreferrer">
            <img src={buySato} alt="Buy $ATO!" className="h-20 3xl:h-32 duration-300 hover:scale-110" />
          </a>
          <a href="https://dexscreener.com/solana/c4zub3dbceu7jqosu3ikc5dkkqbxdttuvebncjcesl7g" target="_blank" rel="noopener noreferrer">
            <img src={chart} alt="Chart" className="h-20 3xl:h-32 duration-300 hover:scale-110" />
          </a>
        </div>
      </div>

      {/* Icon Container positioned inside the arc shape */}
      <div
        style={{
          background: 'linear-gradient(to right, #c0c1c3, #ececed)',
          border: '2px solid #abadb0',
        }}
        className="z-50 absolute left-0 right-0 mx-auto bottom-0 w-full max-w-2xl sm:max-w-3xl 3xl:max-w-4xl rounded-full p-3 flex justify-around items-center gap-1 sm:gap-4 3xl:gap-8"
      >
        <a
          href="https:/www.example.com/1"
          target="_blank"
          rel="noopener noreferrer"
          className="pointer-events-none w-16 h-16 sm:w-24 sm:h-24 3xl:w-32 3xl:h-32 rounded-full flex justify-center items-center -mt-12 sm:-mt-16 3xl:-mt-20 duration-300 hover:scale-125"
        >
          <img src={meta} alt="Meta" className="h-full w-full object-contain" />
        </a>
        <a
          href="https://dexscreener.com/solana/c4zub3dbceu7jqosu3ikc5dkkqbxdttuvebncjcesl7g"
          target="_blank"
          rel="noopener noreferrer"
          className="w-16 h-16 sm:w-24 sm:h-24 3xl:w-32 3xl:h-32 rounded-full flex justify-center items-center -mt-12 sm:-mt-16 3xl:-mt-20 duration-300 hover:scale-125"
        >
          <img src={eagle} alt="Eagle" className="h-full w-full object-contain" />
        </a>
        <a
          href="https:/www.example.com/3"
          target="_blank"
          rel="noopener noreferrer"
          className="pointer-events-none w-16 h-16 sm:w-24 sm:h-24 3xl:w-32 3xl:h-32 rounded-full flex justify-center items-center -mt-12 sm:-mt-16 3xl:-mt-20 duration-300 hover:scale-125"
        >
          <img src={s} alt="S" className="h-full w-full object-contain" />
        </a>
        <a
          href="https://www.tiktok.com/@avocato.lol?_t=8pnndq0NEG1&_r=1"
          target="_blank"
          rel="noopener noreferrer"
          className="w-16 h-16 sm:w-24 sm:h-24 3xl:w-32 3xl:h-32 rounded-full flex justify-center items-center -mt-12 sm:-mt-16 3xl:-mt-20 duration-300 hover:scale-125"
        >
          <img src={tiktok} alt="TikTok" className="h-full w-full object-contain" />
        </a>
        <a
          href="https://t.me/Ato_sol"
          target="_blank"
          rel="noopener noreferrer"
          className="w-16 h-16 sm:w-24 sm:h-24 3xl:w-32 3xl:h-32 rounded-full flex justify-center items-center -mt-12 sm:-mt-16 3xl:-mt-20 duration-300 hover:scale-125"
        >
          <img src={telegram} alt="Telegram" className="h-full w-full object-contain" />
        </a>
        <a
          href="https://www.instagram.com/avocato.lol?igsh=MW02NDhxdXZ1YTBzYQ=="
          target="_blank"
          rel="noopener noreferrer"
          className="w-16 h-16 sm:w-24 sm:h-24 3xl:w-32 3xl:h-32 rounded-full flex justify-center items-center -mt-12 sm:-mt-16 3xl:-mt-20 duration-300 hover:scale-125"
        >
          <img src={ig} alt="Instagram" className="h-full w-full object-contain" />
        </a>
        <a
          href="https://x.com/AvocatoSolana"
          target="_blank"
          rel="noopener noreferrer"
          className="w-16 h-16 sm:w-24 sm:h-24 3xl:w-32 3xl:h-32 rounded-full flex justify-center items-center -mt-12 sm:-mt-16 3xl:-mt-20 duration-300 hover:scale-125"
        >
          <img src={x} alt="X" className="h-full w-full object-contain" />
        </a>
        <a
          href="https://pump.fun/HJXh1XULVe2Mdp6mTKd5K7of1uFqBTbmcWzvBv6cpump"
          target="_blank"
          rel="noopener noreferrer"
          className="w-16 h-16 sm:w-24 sm:h-24 3xl:w-32 3xl:h-32 rounded-full flex justify-center items-center -mt-12 sm:-mt-16 3xl:-mt-20 duration-300 hover:scale-125"
        >
          <img src={pumpfun} alt="PumpFun" className="h-full w-full object-contain" />
        </a>
      </div>





    </div>
  );
};

export default Header;
