import React, { useState, useEffect, useRef } from 'react';
import aboutBgMobile from '../assets/aboutBgMobile.png';
import aboutBgDesktop from '../assets/aboutBgDesktop.png';
import atoIsAnAvocadoCat from '../assets/atoIsAnAvocadoCat.png';
import whoIsAtoText from '../assets/whoIsAtoText.png';
import whoIsAtoImg from '../assets/whoIsAtoImg.png';
import originText from '../assets/originText.png';
import originImg from '../assets/originImg.png';
import theFutureText from '../assets/theFutureText.png';
import theFutureImg from '../assets/theFutureImg.png';
import scrollIcon from '../assets/scrollIcon.png'; 

const About = () => {
  const [backgroundImage, setBackgroundImage] = useState(aboutBgDesktop);
  const [scrollProgress, setScrollProgress] = useState(0);
  const aboutRef = useRef(null);
  const gridRef = useRef(null);

  const handleScroll = () => {
    if (aboutRef.current && gridRef.current) {
      const gridTop = gridRef.current.getBoundingClientRect().top;
      const gridHeight = gridRef.current.offsetHeight;
      const windowHeight = window.innerHeight;

      const visibleHeight = Math.max(0, windowHeight - gridTop);
      const progress = (visibleHeight / (windowHeight + gridHeight)) * 100;

      const clampedProgress = Math.min(Math.max(progress, 0), 100);
      setScrollProgress(clampedProgress);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    handleScroll();

    const mediaQuery = window.matchMedia('(min-width: 1024px)');

    const handleMediaQueryChange = (e) => {
      if (e.matches) {
        setBackgroundImage(aboutBgDesktop);
      } else {
        setBackgroundImage(aboutBgMobile);
      }
    };

    handleMediaQueryChange(mediaQuery);
    mediaQuery.addEventListener('change', handleMediaQueryChange);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      mediaQuery.removeEventListener('change', handleMediaQueryChange);
    };
  }, []);

  return (
    <div
      id="about"
      ref={aboutRef}
      className="relative bg-cover bg-top bg-no-repeat min-h-screen flex flex-col items-center px-4 pt-40 md:pt-44 2xl:pt-60 3xl:pt-96 pb-72 md:pb-96 lg:pb-[40rem] 3xl:pb-[45rem] z-40 -mb-32 sm:-mb-52 lg:-mb-80 3xl:-mb-[30rem]"
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundPosition: 'center bottom',
        backgroundSize: 'cover',
      }}
    >
      <div className="flex flex-col justify-center items-center px-4 md:px-44 3xl:px-40 w-full relative">
        {/* Progress Bar */}
        <div className="w-full">
          <div
            className="absolute top-72 3xl:top-96 left-1/2 transform -translate-x-1/2 hidden md:block"
            style={{
              height: gridRef.current?.offsetHeight,
              width: '10px',
              background: `linear-gradient(to bottom, #A6A8AB, #FFFFFF)`,
            }}
          >
            <div
              className="w-full absolute top-0"
              style={{
                height: `${scrollProgress}%`,
                background: `linear-gradient(to bottom, #009054, #C1D349)`,
              }}
            >
              <img
                src={scrollIcon}
                alt="scroll icon"
                className="absolute bottom-0 left-1/2 transform -translate-x-1/2"
              />
            </div>
          </div>

          {/* Mobile Progress Bar */}
          <div
            className="absolute left-1 top-72 block md:hidden"
            style={{
              height: gridRef.current?.offsetHeight,
              width: '10px',
              background: `linear-gradient(to bottom, #A6A8AB, #FFFFFF)`,
            }}
          >
            <div
              className="w-full absolute top-0"
              style={{
                height: `${scrollProgress}%`,
                background: `linear-gradient(to bottom, #009054, #C1D349)`,
              }}
            >
              <img
                src={scrollIcon}
                alt="scroll icon"
                className="absolute bottom-0 left-1/2 transform -translate-x-1/2"
              />
            </div>
          </div>
        </div>

        <div className="mt-20 mb-16 3xl:mt-18 3xl:mb-10">
          <img
            src={atoIsAnAvocadoCat}
            alt="ATO is an avocado cat"
            className="w-full 3xl:w-[35rem]"
          />
        </div>

        <div
          ref={gridRef}
          className="grid grid-cols-1 md:grid-cols-2 gap-12 w-full ml-4 sm:ml-0 3xl:gap-8"
        >
          {/* Card 1 */}
          <div className="order-1 md:order-1 flex justify-center items-center">
            <img src={whoIsAtoText} alt="Who is ATO" className="md:h-1/2 3xl:h-[40%]" />
          </div>

          <div className="order-2 md:order-2 flex justify-center">
            <img src={whoIsAtoImg} alt="Who is ATO" className="3xl:w-[50%]" />
          </div>

          {/* Card 2 */}
          <div className="order-4 md:order-3 flex justify-center">
            <img src={originImg} alt="Origin" className="3xl:w-[50%]" />
          </div>
          <div className="order-3 md:order-4 flex justify-center items-center">
            <img src={originText} alt="Origin" className="md:h-1/2 3xl:h-[40%]" />
          </div>

          {/* Card 3 */}
          <div className="order-5 md:order-5 flex justify-center items-center">
            <img src={theFutureText} alt="The Future" className="md:h-1/2 3xl:h-[40%]" />
          </div>
          <div className="order-6 md:order-6 flex justify-center">
            <img src={theFutureImg} alt="The Future" className="3xl:w-[50%]" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
