import React, { useState, useEffect } from 'react';

import howToBuyBgMobileNew from '../assets/howToBuyBgMobileNew.png';
import howToBuyBgDesktopNew from '../assets/howToBuyBgDesktopNew.png';
import howToBuy from '../assets/howToBuy.png';

import number1 from '../assets/1.png';
import number2 from '../assets/2.png';
import number3 from '../assets/3.png';
import number4 from '../assets/4.png';

import contractAddress from '../assets/contractAddress.png';

const HowToBuy = () => {
  const [backgroundImage, setBackgroundImage] = useState(howToBuyBgMobileNew);
  const [showNotification, setShowNotification] = useState(false);

  const handleShareClick = () => {
    navigator.clipboard.writeText('HJXh1XULVe2Mdp6mTKd5K7of1uFqBTbmcWzvBv6cpump')
      .then(() => {
        setShowNotification(true);
        setTimeout(() => setShowNotification(false), 3000);
      })
      .catch((err) => {
        console.error('Failed to copy: ', err);
      });
  };

  useEffect(() => {
    const mediaQuery = window.matchMedia('(min-width: 1024px)');

    const handleMediaQueryChange = (e) => {
      if (e.matches) {
        setBackgroundImage(howToBuyBgDesktopNew);
      } else {
        setBackgroundImage(howToBuyBgMobileNew);
      }
    };

    handleMediaQueryChange(mediaQuery);
    mediaQuery.addEventListener('change', handleMediaQueryChange);

    return () => {
      mediaQuery.removeEventListener('change', handleMediaQueryChange);
    };
  }, []);

  return (
    <div
      id="howToBuy"
      className="relative bg-cover bg-top bg-no-repeat min-h-screen flex flex-col items-center px-4 pt-20 sm:pt-48 md:pt-40 3xl:pt-80 pb-[140vw] md:pb-[120vw] lg:pb-[80vw] xl:pb-[60vw] z-30 -mb-32 sm:-mb-72 lg:-mb-96 3xl:-mb-[33rem]"
      style={{ backgroundImage: `url(${backgroundImage})`, backgroundPosition: 'center bottom', backgroundSize: 'cover' }}
    >
      <div className="mt-20 mb-16 3xl:mt-28 3xl:mb-24">
        <img src={howToBuy} alt="How to buy" className="w-full 3xl:w-96" />
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-16 w-full max-w-4xl mx-auto 3xl:max-w-6xl 3xl:gap-20">
        {/* Grid Item 1 */}
        <div className="relative bg-[#e4e5e6] p-8 3xl:p-12 rounded-[2rem] transition-transform duration-300 hover:scale-105 hover:bg-[#c5da5a] flex flex-col items-center text-center text-[#2e1b07] aspect-auto">
          <img src={number1} alt="Step 1" className="absolute -top-10 w-16 3xl:w-20 h-16 3xl:h-20 object-contain" />
          <h3 className="mt-4 3xl:mt-8 text-2xl 3xl:text-6xl font-bold">CREATE WALLET</h3>
          <p className="mt-4 3xl:mt-6 text-lg 3xl:text-3xl">
            Download Phantom or your wallet of choice from the app store or Google Play for free. Desktop users, download the Google Chrome extension by going to Phantom.
          </p>
        </div>

        {/* Grid Item 2 */}
        <div className="relative bg-[#e4e5e6] p-8 3xl:p-12 rounded-[2rem] transition-transform duration-300 hover:scale-105 hover:bg-[#c5da5a] flex flex-col items-center text-center text-[#2e1b07] aspect-auto">
          <img src={number2} alt="Step 2" className="absolute -top-10 w-16 3xl:w-20 h-16 3xl:h-20 object-contain" />
          <h3 className="mt-4 3xl:mt-8 text-2xl 3xl:text-6xl font-bold">GET SOME SOL</h3>
          <p className="mt-4 3xl:mt-6 text-lg 3xl:text-3xl">
            Have SOL in your wallet to switch to $ATO. If you don’t have any SOL, you can buy directly on Phantom, transfer from another wallet, or buy on another exchange and send it to your wallet.
          </p>
        </div>

        {/* Grid Item 3 */}
        <div className="relative bg-[#e4e5e6] p-8 3xl:p-12 rounded-[2rem] transition-transform duration-300 hover:scale-105 hover:bg-[#c5da5a] flex flex-col items-center text-center text-[#2e1b07] aspect-auto">
          <img src={number3} alt="Step 3" className="absolute -top-10 w-16 3xl:w-20 h-16 3xl:h-20 object-contain" />
          <h3 className="mt-4 3xl:mt-8 text-2xl 3xl:text-6xl font-bold">GO TO JUPITER</h3>
          <p className="mt-4 3xl:mt-6 text-lg 3xl:text-3xl">
            Connect to Jupiter. Connect your wallet in Chrome. Paste the $ATO token address, select $ATO, and confirm. When Phantom prompts you for a wallet signature, sign.
          </p>
        </div>

        {/* Grid Item 4 */}
        <div className="relative bg-[#e4e5e6] p-8 3xl:p-12 rounded-[2rem] transition-transform duration-300 hover:scale-105 hover:bg-[#c5da5a] flex flex-col items-center text-center text-[#2e1b07] aspect-auto">
          <img src={number4} alt="Step 4" className="absolute -top-10 w-16 3xl:w-20 h-16 3xl:h-20 object-contain" />
          <h3 className="mt-4 3xl:mt-8 text-2xl 3xl:text-6xl font-bold">SWAP FOR $ATO</h3>
          <p className="mt-4 3xl:mt-6 text-lg 3xl:text-3xl">
            Switch SOL for $ATO. We have zero taxes, so you don’t need to worry about buying with specific slippage.
          </p>
        </div>
      </div>

      <div className="mt-16 3xl:mt-40">
        <img src={contractAddress} className="cursor-pointer duration-300 hover:scale-110 w-full 3xl:w-[40rem]" onClick={handleShareClick} alt="Contract Address" />

        {showNotification && (
          <div className="absolute left-1/2 transform -translate-x-1/2 text-center w-96 3xl:w-[40rem] 3xl:text-3xl bg-green-950 text-white py-2 px-4 rounded-md text-sm font-normal">
            CA copied to clipboard!
          </div>
        )}
      </div>
    </div>
  );
};

export default HowToBuy;
